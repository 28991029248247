@import 'variables';
@import 'bootstrap';
@import 'design/header';
@import 'design/footer';

.logo {
 a {
  text-decoration: none;
  color: var(--bs-dark);
  [class*=fa-] {
   font-size: 240%;
   line-height: 50px;
   background: linear-gradient(to right, $primary, darken($primary, 20%));
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
  }
 }
}
.btn-primary {
 &, &:hover, &:focus {
  color: $white;
 }
}

// temp placement for design items

.overlay {
 position: absolute;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
 z-index: 0;
 .hasBgImg & {
  opacity: .9;
  ~ * {
   position: relative;
   z-index: 1;
  }
 }
 &.bg-primary {
  &::before {
   position: absolute;
   top: 0;
   content: '';
   bottom: 0;
   right: 0;
   left: 0;
   z-index: 1;
   background-color: rgba($white,0.25);
  }
 }
}
.hasBgImg {
 background-repeat: no-repeat;
 background-size: cover;
 background-position: center center;
}
#splashImage {
 .image {
  max-height: 550px;
  overflow: hidden;
  margin-top: -50px;
  margin-left: -50px;
  margin-right: -50px;
  z-index:-1;
  &.overlay::after {
   content: '';
   top: 0;
   bottom: 0;
   left: 0;
   position: absolute;
   right: 0;
   background-image: linear-gradient(45deg, black, transparent);
  }
 }
 @include media-breakpoint-up(sm) {
  .teaserText {
   hyphens: auto;
   text-align: justify;
   max-width: 49ch;
  }
 }
 .boxes {
  .btn {
   position: relative;
   transition: color .3s ease, background-color .4s ease-in-out;
   &:before {
    content: '';
    background-color: $white;
    top: 0;
    z-index: -1;
    left: 0;
    right: 0;
    transform-origin: 50% 50%;
    transition: transform .4s ease-in-out, background-color .5s ease;
    bottom: 0;
    position: absolute;
    transform: scale(0);
   }
   &:hover, &:focus {
    background-color: transparent;
    border-color: transparent;
    color: $black;
    &:before {
     transform: scale(1);
    }
   }
  }
  .record {
   transition: transform .35s ease-in-out;
   transform-origin: 50% 50%;
   &:hover {
    transform: scale3d(1.05, 1.05, 1.05);
    z-index: 1;
   }
  }
  p {
   font-size: $font-size-base * 1.05;
  }
 }
}
#cta {
 position: relative;
 display: flex;
 flex-direction: column;
 align-items: center;
 .container {
  padding-top: 3rem;
  padding-bottom: 2rem;
 }
 &::before, &::after {
 }
 &::after {
  position: absolute;
  top: 0;
  content: '';
  --triangleSize: 32px;
  z-index: 2;
  width: 0;
  height: 0;
  border-left: var(--triangleSize) solid transparent;
  border-right: var(--triangleSize) solid transparent;
  border-top: calc(#{var(--triangleSize)}*1.15) solid $white;
 }
}

.card {
 &.transformer {
  &:not(:last-of-type) {
   margin-bottom: 1.5rem;
  }
  border-width: 0;
  align-items: stretch;
  justify-content: stretch;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
   'icon title'
   'icon content'
   '. content'
  ;
  > * {
   padding: 0 $spacer;
   > * {
    &:only-of-type, &:last-of-type {
     margin-bottom: 0; // targets last or only heading and paragraph tags
   }
  }
 }
 .icon {
  grid-area:icon;
  padding: $spacer;
  height: unset;
  width: unset;
  background-color: $primary;
  color: $light;
 }
 .title {
  grid-area:title;
 }
 .content {
  grid-area:content;
 }
  @include media-breakpoint-up(lg) {
   margin-bottom: 2rem;
   grid-template-areas:
    'icon title'
    'icon content'
    '. content'
   ;
   > * {
    padding-top: $spacer;
    padding-bottom: $spacer;
   }
   .icon {
    width: 77px;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
     max-width: 53px;
    }
   }
   .title, .content {
    padding-top: $spacer/4;
    padding-bottom: $spacer/4;
   }
  }

 }
}

#glassyOverlay {
 .wall {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 683px;
  @include media-breakpoint-up(md) {
   height: unset;
   position: static;
   top: unset;
   right: unset;
   left: unset;
   max-width: 400px;
   overflow: hidden;
  }
  .frame {
   align-items: center;
   display: flex;
   justify-content: center;
   position: relative;
   z-index: 0;
   &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
   }
   img {
    position: relative;
   }
  }
 }
 .glassContent {
  padding: 2rem;
  z-index: 1;
  background-color: rgba($white, .75);
  backdrop-filter: blur(5px);
  .card {
   background-color: transparent;
  }
  @include media-breakpoint-down(lg) {
   margin-top: 7%;
   margin-bottom: 7%;
  }
  @include media-breakpoint-only(md) {
   margin-left: -35%;
   margin-bottom: 5%;
   display: flex;
   flex-direction: column;
   justify-content: center;
  }
 }
}

.carousel-indicators [data-bs-target] {
 width: 15px;
 height: 15px;
 border-radius: 50%;
 border: 1px solid transparent!important;
}

#pageContent, #sidePanel {
 padding: 1rem;
 @include media-breakpoint-up(md) {
  padding-top: 2rem;
  padding-bottom: 2rem;
 }
 @include media-breakpoint-up(xl) {
  padding-top: 2rem;
  padding-bottom: 2rem;
 }
}
#pageContent {
 @include media-breakpoint-up(lg) {
  padding-left: 2rem;
  padding-right: 2rem;
 }
 > {
  h1, h2, h3 {margin-bottom: $spacer}
 }
}
#sidePanel {
 position: relative;
 hr {
  background-color: shade-color($primary, 15%);
 }
 &::before {
  background-color: rgba($dark, .1);
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
 }
 @include media-breakpoint-up(md) {
  flex: 0 0 25%;
  max-width: 25%;
  &::before {
   width: 400%;
  }
 }
 @include media-breakpoint-up(xl) {
  padding-top: 4rem;
 }
}
.form-group {
 margin-bottom: 1.5rem;
}
#staffCards {
 a {
  &.card {
   transition: all .5s ease-in-out;
   color: $body-color;
   &:hover {
    background-color: $primary;
    color: $white;
   }
  }
 }
}
.btn-light {
 &:hover, &:focus {
  background-color: $primary;
  border-color: $primary;
  color: $white;
 }
}
#main, #footer {
 p {
  line-height: 1.8;
 }
}