
$container-max-widths: (
 lg: 960px,
 xl: 1140px,
 xxl: 1320px
);


// Options
$enable-rounded: false;

// Typography
$lead-font-size: 1.2rem;
$lead-font-weight: 400;
$font-size-base: .9rem;
$font-weight-normal: 500;
$font-weight-bolder: 800;
$font-family-sans-serif: 'Montserrat';
$small-font-size: .85em;

// Navs
$nav-link-padding-x: 1.5rem;