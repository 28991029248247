#header {
 .navbar {
  .nav-link {
   padding-left: $spacer;
   padding-right: $spacer;
   color: $white;
   transition: all .5s ease;
   &:hover, &:focus {
    background-color: rgba($black, .8);
    color: $white;
   }
   &:not(.active):not(:active) {
    opacity: .85;
   }
  }
  .navbar-nav:last-of-type {
   .nav-link {
    padding: 10px; // match PSD
   }
  }
  background-color: tint-color($primary, 20%);
 }
 @include media-breakpoint-up(lg) {
  #websiteMainNav {
   margin-right: 2rem;
   margin-top: -1px; // match PSD
   margin-bottom: -4px; // match PSD
  }
 }
 .logo {
  line-height: 1.2;
  padding-left: $spacer/2;
  @include media-breakpoint-up(lg) {
   margin-top: -1px; // match PSD
   margin-left: -14px; // match PSD
  }
  .fs-4 {
   font-size: 26px!important; // match PSD
   margin-bottom: -7px; // match PSD
   margin-left: -1px; // match PSD
  }
  small {
   margin-left: 2px; // match PSD
  }
 }
}
#auxMenu {
 margin-top: -6px; // match PSD
 .nav-item {
  @media screen and (min-width: 992px) and (max-width: 1300px) {
   margin-top: .5rem;
  }
  font-size: calc(.75rem + 0.12vw);
  font-weight: 600;
  line-height: 19px; // match PSD
  padding-left: $spacer;
  padding-right: $spacer;
 }
 .icon {
  text-align: center;
  color: tint-color($primary, 20%);
  margin-right: $spacer/2;
  font-size: calc(1.26rem + 0.12vw);
 }
 .nav-link {
  padding: 0 1rem 0 0;
  color: $dark;
  small {
   font-size: 11px; // match PSD
   font-weight: 700;
   line-height: 1.4;
   opacity: .7;
   text-transform: uppercase;
  }
  &:hover, &:focus {
   color: $primary;
   opacity: 1;
  }
 }
}