#footer {
 $altLinkColor: $gray-500;
 background-color: $dark;
 &, .logo a {
  color: $white;
 }
 a {
  text-decoration: none;
 }
 .nav-link {
  color: $altLinkColor;
 }
 #privyNav {
  .nav-item {
   &:last-child {
    .nav-link {
     border-right: 1px solid $altLinkColor;
    }
   }
  }
  .nav-link {
   padding: 0 $spacer/2;
   border-left: 1px solid $altLinkColor;
  }
 }
}